.Graph {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
	font-family: "trebuchet ms, sans-serif";
}
.app-container {
  display: flex;
	height: 100%;
}

.sidebar-container {
  width: 300px;
	padding: 20px;
	height: 100%;
	color: "#EEEEE4";
}

.main-content {
	padding: 20px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pro-sidebar .pro-menu .pro-menu-item{
	font-family: "trebuchet ms, sans-serif";
}
